<template>
  <div class="big-body">
    <div class="top-wrapper">
      <div class="inner-top-wrapper">
        <div class="first-content">
          <div class="inner-container">
            At INCREDEVENT, we strive to offer the most customized ticketing
            solution online for the fairest rates in the industry. You can trust
            that your ticket selling needs will be met, and that your event will
            run smooth when selling with us! Check out these many great features
            available to you for FREE!
          </div>
        </div>
        <div class="flex-wrapper container">
          <div class="content-box left">
            <div class="content-image-box">
              <img
                class="content-image"
                src="@/images/icons/icon-shieldheck.png"
                alt="Buy Tickets"
              />
              <h4>Fair Fees</h4>
            </div>
            <div class="box-content">
              We know you want your customers to have the best experience from
              the time you start advertising your event until they leave the
              venue. This means fair fees on their purchases. When we calculated
              our fees, we considered every angle, as we are a team of event
              coordinators as well. Fair fees that you won't find anywhere else
              that we as event coordinators are okay with.
            </div>
          </div>
          <!-- <div class="content-box right">
            <div class="content-image-box">
              <img
                class="content-image"
                src="/img/icons/icon-speaker.png"
                alt="Buy Tickets"
              />
               <img
                class="content-image"
                src="/img/icons/icon-shop.png"
                alt="Buy Tickets"
              />
            <h4>Promoter & Vendor Reporting</h4>
            </div>
           <div class="promoters-vendors-img">
              <img
                src="https://lh3.googleusercontent.com/_VXtBlygkGrlWwABmZ_gIlFTrm9fW7GUOG8q_IO2ttHX83UYX-DCB3pxIUAm-RQ1bAaZ49JH-JVoeaHF_hQNGpnVTw0Am7W9gAwQxUafT1KKQQMV1-g-ae0VN8aAGxXUbpNZw1qO"
                class="img-a"
                alt="Track Promoters"
              />
              <img
                src="https://lh5.googleusercontent.com/9BoMQ_Peu6IROuJaqGVBDDuQ-obRmOORhcfhW50Z2nTwU0WT-b_fcs_bS5xJyqVya5gFt0H9vVmmgPi47HN107hyiuJ0NLjcsI1-e1BCgqosbGrkfiJwAOD6-viIs9ypI6Xore8w"
                class="img-a"
                alt="Vendors"
              />
            </div> -->
          <!-- <h4>Track Promoters &amp; Vendors</h4> -->
          <!-- <div class="box-content">
              With INCREDEVENT, any business that has internet (all of them) can sell
              tickets for you. Your promoters can also get the credit they
              deserve for their efforts. Add promoters and vendors to your event
              in seconds, giving them a secret code to process cash sales and
              promote your event with a customzied link. You'll always know
              who's performing on your behalf for every event.
            </div> -->
          <!-- </div>  -->
          <div class="content-box left">
            <div class="content-image-box">
              <img
                class="content-image"
                src="@/images/icons/icon-calendar.png"
                alt="Buy Tickets"
              />
              <h4>Free Event Creation</h4>
            </div>

            <div class="box-content">
              Never pay to list an event, and never receive a charge for free
              events. We host your event for free on our site, and even allow
              you to offer free tickets with no service fees. This is because we
              know what you need to succeed. Many of our tools are free as well.
              Let's put it this way ... running your event online and selling
              tickets will never be a burden for you. Gracious, huh?
            </div>
          </div>

          <div class="content-box right">
            <div class="content-image-box">
              <img
                class="content-image"
                src="@/images/icons/icon-cms.png"
                alt="Buy Tickets"
              />
              <h4>Built-in CMS System</h4>
            </div>

            <div class="box-content">
              INCREDEVENT is your event ticketing hub. Not only can you sell
              tickets at fair rates, but you can contact your buyers when needed
              with important updates, or to announce your next event. In less
              than 2-minutes you can send any combination (by tier, or to the
              whole event) of ticket buyers an email right from inside
              INCREDEVENT. The first 3 emails are free no matter the number of
              attendees.
            </div>
          </div>
          <div class="content-box left">
            <div class="content-image-box">
              <img
                class="content-image"
                src="@/images/icons/icon-stopwatch.png"
                alt="Buy Tickets"
              />
              <h4>Events Live in Minutes</h4>
            </div>
            <div class="box-content">
              It takes a newbie less than 10-minutes to make an event on our
              website. In fact, just in case you're not the most tech savvy
              person, we've created easy to digest video tutorials for you.
              Basically, it requires little experience to become an expert. Not
              sure of your spelling and grammar? Our writing team will even
              proof read your event to ensure no errors when you go live.
            </div>
          </div>
          <div class="content-box right">
            <div class="content-image-box">
              <img
                class="content-image"
                src="@/images/icons/icon-tag.png"
                alt="Buy Tickets"
              />
              <h4>Comp &amp; Promo Codes</h4>
            </div>
            <div class="box-content">
              Our easy to use interface makes it possible to setup promo codes
              on the fly. It's just as easy to issue comps as well. Every detail
              is carefully managed to make sure you can come back to your comps
              and codes &amp; know exactly who you issued to, why, and if need
              be, you can remove select information from the system. It's never
              been easier to take care of your loyal buyers.
            </div>
          </div>
          <div class="content-box left">
            <div class="content-image-box">
              <img
                class="content-image"
                src="@/images/icons/icon-barcode.png"
                alt="Buy Tickets"
              />
              <h4>Encoded Tickets</h4>
            </div>
            <div class="box-content">
              The process is simple: When someone buys a ticket, their ticket is
              unique. That ticket is scanned using our app on your smartphone
              for free, or using a scanner you can purchase from us, and no one
              else can use that ticket. Scan via mobile or print out. You can
              even track the scanner stats live, or after the event has
              concluded. With INCREDEVENT, there is no confusion at the door.
            </div>
          </div>
          <div class="content-box right">
            <div class="content-image-box">
              <img
                class="content-image"
                src="@/images/icons/icon-gear.png"
                alt="Buy Tickets"
              />
              <h4>Detailed Dashboard</h4>
            </div>
            <div class="box-content">
              Never wonder how your event is doing. Whether you're looking to
              contact your ticket buyers, examine your up-to-the-minute
              financials, or figure out which of your staff and vendors are
              performing, INCREDEVENT has your back. We leave no detail
              unchecked, and hey, if you have any suggestions to improve your
              experience, please let us know. We improve the site all the time.
            </div>
          </div>
          <!-- <a
            href="#"
            class="sign-up-btn"
            v-if="!user.isAuthenticated"
            v-on:click.prevent="loadModal('rmodal')"
            >SIGN UP &amp; START SELLING TICKETS</a
          > -->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.container {
  max-width: 1200px;
  margin: auto;
}
.inner-container {
  max-width: 1100px;
  // width:90%;
  margin: auto;
}
.big-body {
  -webkit-box-shadow: inset 0 0 50px 40px #171717e0;
  box-shadow: inset 0 0 50px 40px #171717e0;
  background-color: #222222;
}
.img-a {
  height: 103px;
  width: 103px;
  margin: 0 0.75em;
}
.img {
  height: 103px;
  width: 288px;
}
.box-content {
  font-size: 14px;
  margin-top: 65px;
  color: var(--text);
  line-height: 24px;
  // margin: 2em 2em;
}
.content-box {
  display: block;
  // width: 40%;
  height: fit-content;

  height: fit-content;
  position: relative;
  backdrop-filter: blur(4px);
  padding: 40px;
  margin: 0px 20px 80px;
  max-width: 480px;
  width: 100%;
  // justify-content: center;
  border: 1px solid var(--dashboard-border);
  border-radius: 10px;
  text-align: left;
  // background-color: rgba(0, 0, 0, 0.1);
  background-image: var(--dashboard-tiles);
  box-shadow: var(--other-shadow);
}

.content-image-box {
  width: 100%;
  position: absolute;
  top: -10%;
  right: 0;
  text-align: center;

  h4 {
    margin: 10px 0;
    color: var(--text);
    font-weight: 700;
  }
}
.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.sign-up-btn {
  background: #4fb95e;
  border: 0px;
  font-weight: 100;
  color: #ffffff;
  padding: 9px 55px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0px;
  box-shadow: none;
  transition: all 0.5s ease-out;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  margin: 4em 0;
}
.top-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-image: url("../images/TTSplashBG.jpg");

  // background-image: var(--features-splash);
  background-size: contain;
  background-repeat: no-repeat;
  // background-position: center;
  background-color: var(--content-background);
}
#app.light {
  .top-wrapper {
    background-image: url("../images/TTSplash-Light.jpg");
  }
  // background-image: var(--features-splash);
}
.inner-top-wrapper {
  text-align: center;
}
h3 {
  margin: 1em 0;
  font-size: 2.25em;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}
.router-link {
  width: 310px;
  padding: 5px 0;
  text-align: center;
  background-color: #419e4f;
  border: none;
  color: #ffffff;
}
.first-content {
  padding: 80px 20px;
  font-size: 14px;
  text-align: left;
  font-weight: 100;

  // margin: 1.5em 14em;
  // margin-top: 4em;
  // margin-bottom: 4em;
  // font-size: 14px;
  // text-align: left;
  // font-weight: 100;
}
</style>

<script>
export default {
  name: "planner-features",
  head() {
    return {
      title: "Manage Events & Sell Tickets Online with INCREDEVENT Planner",
      meta: [
        {
          name: "description",
          content:
            "Innovative event management software & ticketing solution. Fair fees. Detailed dashboard. Built-in CMS. Check out our industry-leading planner features!",
        },
        {
          name: "keywords",
          content: "Events, Tickets, Directory, Promotion",
        },
        {
          name: "author",
          content: "INCREDEVENT",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
      ],
    };
  },
  data() {
    return {
      rmodal: false,
      alrtModal: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    loadModal(val) {
      this[val] = true;
    },
    dismiss(val) {
      this[val] = false;

      if (this[val] == "amodal") {
        this.rmodal = false;
      }

      document.getElementsByTagName("body")[0].style.overflow = "visible";
    },
    alert() {
      this.alrtModal = true;
      this.alrtMessage = "User created. Please check your Email";
    },
  },
  created() {
    this.$store.commit("setTitle", "Planner Features");
  },
};
</script>
